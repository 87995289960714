<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >商务名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.businessName"
                :fetch-suggestions="businessNameQuerySearch"
                placeholder="请输入或选择商务名称"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete
            ></span>
            <span>
              商务类型：
              <Dictionary
                v-model="parameter.businessType"
                class="ipt_width"
                code="BUSINESS_TYPE"
                placeholder="请选择商务类型"
            /></span>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <!-- <el-button type="primary" icon="el-icon-edit" @click="clear_serach"
            >清空</el-button
          > -->
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">分配流程</el-button>
          <el-button type="success" style="padding: 0"
            ><a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/商务绩效分配与审核操作文档.pdf"
              >操作文档</a
            ></el-button
          >
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.applyType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED">
              <span slot="label">未提交 {{ `(${this.statistics.undetermind})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL">
              <span slot="label">审核中 {{ `(${this.statistics.approval})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT">
              <span slot="label">不通过 {{ `(${this.statistics.approvalReject})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS">
              <span slot="label">已审批 {{ `(${this.statistics.approvalPass})` }}</span>
            </el-tab-pane>
          </el-tabs>

          <el-table
            :data="tableData"
            border
            style="width: 100%"
            height="string"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="50"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="businessName"
              label="商务名称"
              min-width="320"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="ownerUnit"
              min-width="240"
              label="业主单位"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column align="center" prop="contacts" label="业主联系人" width="100">
            </el-table-column>
            <el-table-column align="center" prop="contactsPhone" label="联系电话" width="120">
            </el-table-column>
            <el-table-column
              align="center"
              prop="businessType"
              label="商务类型"
              sortable
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="statusName"
              sortable
              min-width="150"
              label="流程进度"
            >
            </el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  v-if="
                    parameter.applyType == 'APPROVAL_REJECT' ||
                    parameter.applyType == 'UNDETERMINED'
                  "
                  @click="handleClick(scope.row, 1)"
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                >
                  编辑
                </el-button>
                <el-button
                  v-if="parameter.applyType != 'UNDETERMINED'"
                  @click="handleClick(scope.row, 2)"
                  type="text"
                  class="text_Details_Bgc"
                  size="small"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <DistriBution
      v-if="showEditDialog"
      @updateData="updateData"
      :showEditDialog.sync="showEditDialog"
      :options="businessManageIofn"
      :Disabled="Disabled"
      :title="title"
      :distriBution="true"
    ></DistriBution>

    <el-dialog width="75%" title="商务绩效分配、审核流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img
          src="@/assets/商务绩效分配、审核流程图.png"
          alt="商务绩效分配、审核流程图"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        applyType: 'UNDETERMINED',
        businessName: null,
        businessType: null,
      },
      pageSize: 0,
      tableData: [],
      showEditDialog: false,
      dictData: {
        businessType: [],
      },
      businessManageIofn: {},
      title: '',
      Disabled: false,
      businessList: [],
      loading: false,
      drawer: false,
      statistics: {
        undetermind: 0,
        approval: 0,
        approvalReject: 0,
        approvalPass: 0,
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
  },
  watch: {
    'parameter.applyType': {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    getType() {
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.businessExpense
        .businessNameList()
        .then(res => {
          this.businessList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleClick(row, index) {
      this.businessManageIofn = row
      this.businessManageIofn.reject = false
      this.title = '绩效分配'
      this.Disabled = false
      if (index === 2) {
        this.title = '绩效详情'
        this.Disabled = true
        this.businessManageIofn.reject = true
      }
      this.showEditDialog = true
    },
    updateData() {
      this.showEditDialog = false
      this.getData()
    },
    async getData() {
      this.loading = true
      const res = await this.$api.businessPerformance.getApplyForList(this.parameter)
      const Statistics = await this.$api.businessPerformance.getApplyStatistics({
        expenseType: 'BUSINESS',
      })
      if (res.data == null) {
        this.tableData = []
        this.loading = false
      } else {
        this.tableData = res.data.records
        this.parameter.total = res.data.total
        this.loading = false
      }
      if (Statistics.data) {
        Statistics.data.forEach(v => {
          if (v.applyType == 'UNDETERMINED') {
            this.statistics.undetermind = v.sumQuantity
          } else if (v.applyType == 'APPROVAL') {
            this.statistics.approval = v.sumQuantity
          } else if (v.applyType == 'APPROVAL_REJECT') {
            this.statistics.approvalReject = v.sumQuantity
          } else if (v.applyType == 'APPROVAL_PASS') {
            this.statistics.approvalPass = v.sumQuantity
          }
        })
      }
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handleReset() {
      this.parameter.pageNow = 1
      this.parameter.businessName = null
      this.parameter.businessType = null
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
